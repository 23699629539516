import styled, { css } from 'styled-components';
import Img from "gatsby-image"

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${props => props.bg && css`
    background: linear-gradient(to top right, ${props.bg[0]}, ${props.bg[1]});
  `}

  ${props => props.dark && css`
    background: #1a181a;
    color: white;
  `}
`;

Page.Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 960px;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
  padding-top: 140px;
  flex-direction: column;

  svg {
    margin-top: 40px;
  }
`;

Page.Content2 = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 440px) {
    padding: 0 20px;
    text-align: center;
  }
`;

Page.Sun = styled.div`
  position: absolute;
  align-self: flex-end;
  top: 60px;

  @media (max-width: 440px) {
    top: 30px;
    right: 10px;
    transform: scale(0.6);
  }
`;

Page.Moon = styled.div`
  position: absolute;
  align-self: flex-end;
  top: 60px;

  @media (max-width: 440px) {
    top: 30px;
    right: 10px;
    transform: scale(0.6);
  }
`;

Page.Img = styled(Img)`
  @media (max-width: 440px) {
    min-width: 0px !important;
  }
`

export default Page
